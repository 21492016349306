<template>
  <div>
    <Title title="Flightscope Aviation Aircraft Fleet" />
    <div class="fleet">
      <FleetItem planeName="Eurofox 3K &quot;X1&quot;" :images="triple81Images" :properties="triple81" />
      <FleetItem planeName="8KCAB Super Decathlon" :images="nqxImages" :properties="nqx" />
      <FleetItem planeName="Extra 300" :images="cizImages" :properties="ciz" />
      <FleetItem planeName="Cessna 182P Skylane" :images="tinImages" :properties="tin" />
      <FleetItem planeName="Cessna 172R Skyhawk" :images="scnImages" :properties="scn" />
      <FleetItem planeName="Cessna 172S Skyhawk" :images="ocpImages" :properties="ocp" />
      <FleetItem planeName="Cessna 152" :images="nkjImages" :properties="nkj" />
    </div>
  </div>
</template>

<script>
import FleetItem from '@/components/FleetItem.vue'
import Title from '@/components/Title.vue'

export default {
  components: {
    FleetItem,
    Title
  },
  data () {
    return {
      triple81Images: [
        require('@/assets/images/aircraft_images/24-8881/IMG_20210401_171516-800.jpg'),
        require('@/assets/images/aircraft_images/24-8881/IMG_20210401_171506-800.jpg'),
        require('@/assets/images/aircraft_images/24-8881/IMG_20210401_171539-800.jpg'),
        require('@/assets/images/aircraft_images/24-8881/IMG_20210401_171556-800.jpg'),
        require('@/assets/images/aircraft_images/24-8881/IMG_20210401_171605-800.jpg'),
        require('@/assets/images/aircraft_images/24-8881/IMG_20210401_171622-800.jpg'),
        require('@/assets/images/aircraft_images/24-8881/IMG_20210401_171632-800.jpg'),
        require('@/assets/images/aircraft_images/24-8881/IMG_20210401_171721-800.jpg'),
        require('@/assets/images/aircraft_images/24-8881/IMG_20210401_171800-800.jpg'),
        require('@/assets/images/aircraft_images/24-8881/IMG_20210401_171803-800.jpg'),
        require('@/assets/images/aircraft_images/24-8881/IMG_20210401_171825-800.jpg'),
        require('@/assets/images/aircraft_images/24-8881/IMG_20210401_171829-800.jpg'),
        require('@/assets/images/aircraft_images/24-8881/IMG_20210401_171907-800.jpg'),
        require('@/assets/images/aircraft_images/24-8881/IMG_20210401_171910-800.jpg'),
        require('@/assets/images/aircraft_images/24-8881/IMG_20210401_171917-800.jpg'),
        require('@/assets/images/aircraft_images/24-8881/IMG_20210401_171927-800.jpg'),
        require('@/assets/images/aircraft_images/24-8881/IMG_20210401_171954-800.jpg'),
        require('@/assets/images/aircraft_images/24-8881/IMG_20210401_172016-800.jpg'),
        require('@/assets/images/aircraft_images/24-8881/IMG_20210401_172024-800.jpg'),
        // require('@/assets/images/aircraft_images/24-8881/IMG_20210326_171315-800.jpg'),
        // require('@/assets/images/aircraft_images/24-8881/IMG_20210326_171315-cropped1-800.png'),
        require('@/assets/images/aircraft_images/24-8881/IMG_20210326_171315-cropped2-800.png'),
        require('@/assets/images/aircraft_images/24-8881/IMG_20210326_171321-800.jpg')
      ],
      triple81: [
        ['MTOW', '560 kg (1235 lb)'],
        ['Empty', '304 kg (670 lb)'],
        ['Usable Load', '256 kg (564 lb)'],
        ['Cruise', '100 knots (185 kph)'],
        ['Engine', 'Rotax 912ULS 100hp'],
        ['Capacity', '85 L'],
        ['Fuel Burn', '20 L/hr'],
        ['Crew', '1'],
        ['Passengers', '1'],
        ['Manufactured', 'Slovakia']
      ],
      nqxImages: [
        require('@/assets/images/aircraft_images/vh-nqx/left-lateral-600.jpg'),
        require('@/assets/images/aircraft_images/vh-nqx/left-behind2-600.jpg'),
        require('@/assets/images/aircraft_images/vh-nqx/right-lateral-600.jpg'),
        require('@/assets/images/aircraft_images/vh-nqx/left-front-600.jpg'),
        require('@/assets/images/aircraft_images/vh-nqx/right-behind-600.jpg'),
        require('@/assets/images/aircraft_images/vh-nqx/left-lateral-600.jpg'),
        require('@/assets/images/aircraft_images/vh-nqx/cockpit-left-600.jpg'),
        require('@/assets/images/aircraft_images/vh-nqx/cockpit-600.jpg'),
        require('@/assets/images/aircraft_images/vh-nqx/cockpit-breakers-fuel-600.jpg'),
        require('@/assets/images/aircraft_images/vh-nqx/cockpit-right-bottom2-600.jpg'),
        require('@/assets/images/aircraft_images/vh-nqx/cockpit-throttle-600.jpg'),
        require('@/assets/images/aircraft_images/vh-nqx/cockpit-right-bottom-600.jpg'),
        require('@/assets/images/aircraft_images/vh-nqx/front-600.jpg'),
        require('@/assets/images/aircraft_images/vh-nqx/cockpit-right-600.jpg'),
        require('@/assets/images/aircraft_images/vh-nqx/cockpit-left-bottom2-600.jpg'),
        require('@/assets/images/aircraft_images/vh-nqx/cockpit-fuel-600.jpg'),
        require('@/assets/images/aircraft_images/vh-nqx/cockpit-left-bottom-600.jpg'),
        require('@/assets/images/aircraft_images/vh-nqx/left-behind-600.jpg'),
        require('@/assets/images/aircraft_images/vh-nqx/cockpit-left-seat-600.jpg'),
        require('@/assets/images/aircraft_images/vh-nqx/cockpit-rear-seat-600.jpg'),
        require('@/assets/images/aircraft_images/vh-nqx/cockpit-centre-600.jpg'),
        require('@/assets/images/aircraft_images/vh-nqx/right-behind2-600.jpg')
      ],
      nqx: [
        ['MTOW', '884 kg (1950 lb)'],
        ['Empty', '616 kg (1358 lb)'],
        ['Usable Load', '269 kg (592 lb)'],
        ['Cruise', '110 knots (204 kph)'],
        ['Engine', 'Lycoming AEIO-360 180hp'],
        ['Fuel Capacity', '152 L'],
        ['Fuel Burn', '40 L/hr'],
        ['Crew', '1'],
        ['Passengers', '1'],
        ['Manufactured', 'USA']
      ],
      cizImages: [
        require('@/assets/images/aircraft_images/vh-ciz/extra-300.webp'),
        require('@/assets/images/aircraft_images/vh-ciz/BD5C3347-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ciz/BD5C3350-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ciz/BD5C3375-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ciz/BD5C3382-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ciz/BD5C3413-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ciz/BD5C3473-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ciz/BD5C3500-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ciz/BD5C3583-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ciz/BD5C3606-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ciz/BD5C3625-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ciz/BD5C3626-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ciz/BD5C3627-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ciz/BD5C3642-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ciz/BD5C3737-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ciz/BD5C3744-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ciz/BD5C3847-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ciz/extra-300-2-600.jpg')
      ],
      ciz: [
        ['MTOW', '950 kg (2094 lb)'],
        ['Empty', '694 kg (1530 lb)'],
        ['Usable Load', '256 kg (564 lb)'],
        ['Cruise', '160 knots (296 kph)'],
        ['Engine', 'Lycoming AEIO-540 300hp'],
        ['Fuel Capacity', '189 L'],
        ['Fuel Burn', '60 L/hr'],
        ['Crew', '1'],
        ['Passengers', '1'],
        ['Manufactured', 'Germany']
      ],
      tinImages: [
        require('@/assets/images/aircraft_images/vh-tin/IMG_20220126_100121.jpg'),
        require('@/assets/images/aircraft_images/vh-tin/IMG_20220126_100143.jpg'),
        require('@/assets/images/aircraft_images/vh-tin/IMG_20220126_100155.jpg'),
        require('@/assets/images/aircraft_images/vh-tin/IMG_20220126_100215.jpg'),
        require('@/assets/images/aircraft_images/vh-tin/IMG_20220126_100224.jpg'),
        require('@/assets/images/aircraft_images/vh-tin/IMG_20220126_100234.jpg'),
        require('@/assets/images/aircraft_images/vh-tin/IMG_20220126_100252.jpg'),
        require('@/assets/images/aircraft_images/vh-tin/IMG_20220126_100303.jpg'),
        require('@/assets/images/aircraft_images/vh-tin/IMG_20220126_100309.jpg'),
        require('@/assets/images/aircraft_images/vh-tin/IMG_20220126_100316.jpg'),
        require('@/assets/images/aircraft_images/vh-tin/IMG_20220126_100341.jpg'),
        require('@/assets/images/aircraft_images/vh-tin/IMG_20220126_100400.jpg'),
        require('@/assets/images/aircraft_images/vh-tin/IMG_20220126_100442.jpg'),
        require('@/assets/images/aircraft_images/vh-tin/IMG_20220126_100458.jpg'),
        require('@/assets/images/aircraft_images/vh-tin/cockpit-all1.jpg'),
        require('@/assets/images/aircraft_images/vh-tin/cockpit-bottom-centre.jpg'),
        require('@/assets/images/aircraft_images/vh-tin/cockpit-bottom-left.jpg'),
        require('@/assets/images/aircraft_images/vh-tin/cockpit-left2.jpg'),
        require('@/assets/images/aircraft_images/vh-tin/cockpit-right1.jpg')
      ],
      tin: [
        ['MTOW', '1338 kg (2950 lb)'],
        ['Empty', '832 kg (1835 lb)'],
        ['Usable Load', '506 kg (1115 lb)'],
        ['Cruise', '135 knots (250 kph)'],
        ['Engine', 'Continental 6cyl O-470-S 230hp'],
        ['Fuel Capacity', '303 L'],
        ['Fuel Burn', '50 L/hr'],
        ['Crew', '1'],
        ['Passengers', '3'],
        ['Manufactured', 'USA']
      ],
      scnImages: [
        require('@/assets/images/aircraft_images/vh-scn/scn-exterior-600.jpg'),
        require('@/assets/images/aircraft_images/vh-scn/attitude-indicator-600.jpg'),
        require('@/assets/images/aircraft_images/vh-scn/cockpit-06-600.jpg'),
        require('@/assets/images/aircraft_images/vh-scn/cockpit-03-600.jpg'),
        require('@/assets/images/aircraft_images/vh-scn/cockpit-600.jpg'),
        require('@/assets/images/aircraft_images/vh-scn/fuel-instruments-600.jpg'),
        require('@/assets/images/aircraft_images/vh-scn/vh-scn-600.jpg'),
        require('@/assets/images/aircraft_images/vh-scn/cockpit-05-600.jpg'),
        require('@/assets/images/aircraft_images/vh-scn/cockpit-04-600.jpg'),
        require('@/assets/images/aircraft_images/vh-scn/cockpit-07-600.jpg'),
        require('@/assets/images/aircraft_images/vh-scn/cockpit-08-600.jpg'),
        require('@/assets/images/aircraft_images/vh-scn/flap-lever-600.jpg'),
        require('@/assets/images/aircraft_images/vh-scn/pilot-side-600.jpg'),
        require('@/assets/images/aircraft_images/vh-scn/comm-stack-600.jpg'),
        require('@/assets/images/aircraft_images/vh-scn/fuel-tank-instruments-600.jpg'),
        require('@/assets/images/aircraft_images/vh-scn/switches-and-circuit-breakers-600.jpg'),
        require('@/assets/images/aircraft_images/vh-scn/throttle-mixture-control-600.jpg'),
        require('@/assets/images/aircraft_images/vh-scn/cockpit-01-600.jpg'),
        require('@/assets/images/aircraft_images/vh-scn/cockpit-02-600.jpg')
      ],
      scn: [
        ['MTOW', '1111 kg (2450 lb)'],
        ['Empty', '762 kg (1679 lb)'],
        ['Usable Load', '342 kg (771 lb)'],
        ['Cruise', '120 knots (222 kph)'],
        ['Engine', 'Lycoming IO-360 160hp'],
        ['Fuel Capacity', '212 L'],
        ['Fuel Burn', '40 L/hr'],
        ['Crew', '1'],
        ['Passengers', '3'],
        ['Manufactured', 'USA']
      ],
      ocpImages: [
        require('@/assets/images/aircraft_images/vh-ocp/014-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ocp/013-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ocp/002-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ocp/003-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ocp/004-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ocp/005-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ocp/006-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ocp/007-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ocp/008-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ocp/009-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ocp/010-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ocp/011-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ocp/012-600.jpg'),
        require('@/assets/images/aircraft_images/vh-ocp/015-600.jpg')
      ],
      ocp: [
        ['MTOW', '1157 kg (2550 lb)'],
        ['Empty', '796 kg (1755 lb)'],
        ['Usable Load', '361 kg (795 lb)'],
        ['Cruise', '120 knots (222 kph)'],
        ['Engine', 'Lycoming IO-360 180hp'],
        ['Fuel Capacity', '212 L'],
        ['Fuel Burn', '40 L/hr'],
        ['Crew', '1'],
        ['Passengers', '3'],
        ['Manufactured', 'USA']
      ],
      nkjImages: [
        require('@/assets/images/aircraft_images/vh-nkj/001.jpg'),
        require('@/assets/images/aircraft_images/vh-nkj/002.jpg'),
        require('@/assets/images/aircraft_images/vh-nkj/003.jpg'),
        require('@/assets/images/aircraft_images/vh-nkj/004.jpg'),
        require('@/assets/images/aircraft_images/vh-nkj/005.jpg')
      ],
      nkj: [
        ['MTOW', '757 kg (1669 lb)'],
        ['Empty', '534 kg (1177 lb)'],
        ['Usable Load', '223 kg (492 lb)'],
        ['Cruise', '95 knots (176 kph)'],
        ['Engine', 'Lycoming O-235-L2C 110hp'],
        ['Fuel Capacity', '100 L'],
        ['Fuel Burn', '23 L/hr'],
        ['Crew', '1'],
        ['Passengers', '1'],
        ['Manufactured', 'USA']
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.fleet {
  display: inline-grid;
  width: 100%;
  @media screen and (max-width: 600px) { grid-template-columns: 1fr; }
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  row-gap: 5vh;
}
</style>
