<template>
  <div class="fleet-item">
    <h2>{{ planeName }}</h2>
    <!-- <img :src="imagePath" /> -->
    <splide :options="options">
      <splide-slide v-for="(image, index) in images" :key="index">
        <a :href="image" >
          <img :src="image" />
        </a>
      </splide-slide>
    </splide>
    <table class="propertyList">
      <tr v-for="(property, index) of properties" :key="index">
        <td>{{ property[0] }}</td>
        <td v-if="property.length == 2">{{ property[1] }}</td>
        <td v-else><router-link :to="{path: '/aircraft-documentation', hash: property[2]}">{{ property[1] }}</router-link></td>
      </tr>
    </table>
  </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'

export default {
  props: ['planeName', 'images', 'properties'],
  components: {
    Splide,
    SplideSlide
  },
  data () {
    return {
      options: {
        type: 'loop',
        lazyLoad: 'nearby'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/styles/_presets.scss';
@import '@/styles/_colors.scss';

.fleet-item {
  display: flex;
  flex-direction: column;
  width: 60vh;
  max-width: 90vw;

  img {
    width: 60vh;
    height: 40vh;
    max-width: 90vw;
    object-fit: cover;
    @extend .rounded;
  }
}

.propertyList {
  border-collapse: collapse;
  width: 100%;
  margin-top: 5vh;

  td {
    padding: 0.5em;
    border: 1px solid mix($primary, $secondary);
    vertical-align: middle;
    text-align: right;
  }

  td:nth-child(1) {
    background-color: $primary;
    color: $secondary;
    text-align: left;
    font-weight: 500;
  }
}
</style>
